/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      subject
      message
      messageTime
      __typename
    }
  }
`;
export const getSurveyStat = /* GraphQL */ `
  query GetSurveyStat($surveyId: String!) {
    getSurveyStat(surveyId: $surveyId) {
      surveyId
      currentRating
      currentSentiment
      numberResponses
      __typename
    }
  }
`;
export const listSurveyStats = /* GraphQL */ `
  query ListSurveyStats {
    listSurveyStats {
      surveyId
      currentRating
      currentSentiment
      numberResponses
      __typename
    }
  }
`;
