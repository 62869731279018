/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishBroadcastMessage = /* GraphQL */ `
  mutation PublishBroadcastMessage(
    $subject: String!
    $message: String
    $messageTime: AWSDateTime
  ) {
    publishBroadcastMessage(
      subject: $subject
      message: $message
      messageTime: $messageTime
    ) {
      subject
      message
      messageTime
      __typename
    }
  }
`;
export const publishSurveyResult = /* GraphQL */ `
  mutation PublishSurveyResult(
    $surveyId: String!
    $questionId: String!
    $result: String
    $user: String
  ) {
    publishSurveyResult(
      surveyId: $surveyId
      questionId: $questionId
      result: $result
      user: $user
    ) {
      surveyId
      questionId
      result
      user
      __typename
    }
  }
`;
export const updateSurveyStat = /* GraphQL */ `
  mutation UpdateSurveyStat(
    $surveyId: String!
    $currentRating: Int
    $currentSentiment: String
    $numberResponses: Int
  ) {
    updateSurveyStat(
      surveyId: $surveyId
      currentRating: $currentRating
      currentSentiment: $currentSentiment
      numberResponses: $numberResponses
    ) {
      surveyId
      currentRating
      currentSentiment
      numberResponses
      __typename
    }
  }
`;
