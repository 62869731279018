/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPublishBroadcastMessage = /* GraphQL */ `
  subscription OnPublishBroadcastMessage(
    $subject: String
    $message: String
    $messageTime: AWSDateTime
  ) {
    onPublishBroadcastMessage(
      subject: $subject
      message: $message
      messageTime: $messageTime
    ) {
      subject
      message
      messageTime
      __typename
    }
  }
`;
export const onUpdateSurveyStat = /* GraphQL */ `
  subscription OnUpdateSurveyStat($surveyId: String) {
    onUpdateSurveyStat(surveyId: $surveyId) {
      surveyId
      currentRating
      currentSentiment
      numberResponses
      __typename
    }
  }
`;
