import React, { useEffect, useState } from "react";
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import * as broadcastSubscriptions from './graphql/broadcast/subscriptions';
import * as broadcastMutations from './graphql/broadcast/mutations';
import * as broadcastQueries from './graphql/broadcast/queries';
import * as surveyQueries from './graphql/survey/queries';
import awsBroadcast from './aws-exports-broadcast';
import awsSurvey from './aws-exports-survey';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Alert from "react-bootstrap/Alert";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const [broadcastMessage, setBroadcastMessage] = useState();
  const [surveyCount, setSurveyCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [surveys, setSurveys] = useState();
  const [questions, setQuestions] = useState();
  const [surveyResults, setSurveyResults] = useState([]);
  const [surveyStats, setSurveyStats] = useState();
  const [showToast, setShowToast] = useState(false);
  const [showBroadcast, setShowBroadcast] = useState(false);

  // Subscribe to broadcast message

  useEffect(() => {


    Amplify.configure(awsBroadcast);
    const client = generateClient();
    client.graphql({ query: broadcastSubscriptions.onPublishBroadcastMessage })
      .subscribe({
        next: ({ data }) => {
          setBroadcastMessage(data.onPublishBroadcastMessage)
          setShowBroadcast(true);
          console.log(data);
        },
        error: (error) => console.warn(error)
      });

      /*client.graphql({query: broadcastSubscriptions.onUpdateSurveyStat})
       .subscribe({
        next: ({ data}) => {
          updateSurveyStats(data);
        },
        error: (error) => console.warn(error)
       })*/

      client.graphql({query: broadcastQueries.listSurveyStats}).then(result => {
        console.log("got survey stats");
        console.log(result);
        setSurveyStats(result.data.listSurveyStats);
      }).catch(err => console.log(err));


    Amplify.configure(awsSurvey);
    const client2 = generateClient();

    client2.graphql({ query: surveyQueries.listSurveys }).then(result => {
      console.log("got surveys");
      console.log(result);
      const itemLength = result.data.listSurveys.items.length;
      console.log(itemLength);
      setSurveyCount(itemLength);
      setSurveys(result.data.listSurveys.items);
    }).catch(err => console.log(err));

    client2.graphql({ query: surveyQueries.listQuestions }).then(result => {
      console.log("got questions");
      console.log(result);
      const itemLength = result.data.listQuestions.items.length;
      console.log(itemLength);
      setQuestionCount(itemLength);
      setQuestions(result.data.listQuestions.items);
    }).catch(err => console.log(err));

  }, []);

  const sendData = () => {
    Amplify.configure(awsBroadcast);
    const client3 = generateClient();
    const resultData = {
      questionId: "3fb8f018-5f6c-4670-8129-24d9b473938b",
      surveyId: "fdbe5291-967d-443f-82cd-06bcb04700de",
      result: "33"
    };

    client3.graphql({ query: broadcastMutations.publishSurveyResult, variables: resultData })
      .then(result => {
        console.log("Great, saved result!");
      })
      .catch(err => {
        console.log("Error: ", err);
      });

    return;
  }

  const updateSurveyResults = (data, qId, sId) => {
    const resultItem = {
      surveyId: sId,
      questionId: qId,
      result: data
    };
    console.log(surveyResults);
    let newList = [
      ...surveyResults
    ]
    if (newList.find(a => a.questionId === qId)) {
      console.log("Found");
      const uList = newList.find(a => a.questionId === qId);
      uList.result = data;
      setSurveyResults(newList);
    }
    else {
      console.log("Not found");
      newList = [
        ...surveyResults, resultItem
      ]
      setSurveyResults(newList);
    }
  }

  const saveSurveyResults = (sId) => {
    console.log(sId);

    Amplify.configure(awsBroadcast);
    const client3 = generateClient();

    for (var xIndex in surveyResults) {
      const cItem = surveyResults[xIndex];
      console.log(cItem);
      if (cItem.surveyId === sId) {
        const resultData = {
          questionId: cItem.questionId,
          surveyId: cItem.surveyId,
          result: cItem.result
        };

        client3.graphql({ query: broadcastMutations.publishSurveyResult, variables: resultData })
          .then(result => {
            console.log("Great, saved result for: ", cItem.surveyId, cItem.questionId, cItem.result);
            setShowToast(true);
          })
          .catch(err => {
            console.log("Error: ", err);
          });
      }
    }
  }

  const closeToast = () => {
    setShowToast(false);
  }

  const closeBroadcast = () => {
    setShowBroadcast(false);
  }

  const updateSurveyStats = (data) => {
    console.log(data);
    console.log("Surveys:", surveys);
    const surveyStatItem = {
      surveyId: data.onUpdateSurveyStat.surveyId,
      currentRating: data.onUpdateSurveyStat.currentRating,
      currentSentiment: data.onUpdateSurveyStat.currentSentiment,
      numberResponses: data.onUpdateSurveyStat.numberResponses
    };
    let newList = [
      ...surveyStats
    ]
    if (newList.find(a => a.surveyId === surveyStatItem.surveyId)) {
      console.log("Found");
      const uList = newList.find(a => a.surveyId === surveyStatItem.surveyId);
      uList.result = data;
      setSurveyStats(newList);
    }
    else {
      console.log("Not found");
      newList = [
        ...surveyStats, surveyStatItem
      ]
      setSurveyStats(newList);
    }

  }

  return (
    <div>
      <Navbar expand="lg" className="bg-body-teriary" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Survey App (re:Invent 2023)</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/aut">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Row><Col>
          &nbsp;
          <ToastContainer
            className="p-3"
            position="middle-right"
            style={{ zIndex: 1 }}
          >
            <Toast show={showToast} onClose={closeToast}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Survey Results Saved</strong>
                <small>Just now</small>
              </Toast.Header>
              <Toast.Body>Thank you for submitting your results.  You can now fill out other surveys.</Toast.Body>
            </Toast>
          </ToastContainer>
        </Col></Row>
        <Row>
          <Col xs={3}><b>Broadcast Messages</b><br/><i>Messages from the conference admin will show up here!</i>
            <div>
              {broadcastMessage ?
                <>
                  <Alert dismissible show={showBroadcast} onClose={closeBroadcast}>
                    <Alert.Heading>{broadcastMessage.subject}</Alert.Heading>
                    <div dangerouslySetInnerHTML={{    __html: broadcastMessage.message  }}>
                    </div>
                  </Alert>
                </>
                : ""}
            </div>
          </Col>
          <Col><b>Surveys</b>
            <br />Please complete surveys for any sessions you have attended.  There are currently <b>{surveyCount} </b> surveys available

            <div>
              {surveys ?
                <>
                  {surveys.map(sItem => (
                    <div key={sItem.id}>
                      <Card>
                        <Card.Body>
                          <Card.Title>{sItem.title}</Card.Title>
                          <Card.Text><i>{sItem.description}</i>


                        {surveyStats ? surveyStats.filter(ssItem => ssItem.surveyId === sItem.id).map(statItem => (
                          <div key={statItem.surveyId}><b>Responses: {statItem.numberResponses}, Current Sentiment: {statItem.currentSentiment}, Current Rating: {statItem.currentRating}</b></div>
                        )) : ""}
                        </Card.Text>
                          {questions ? questions.filter(fQItem => fQItem.surveyID === sItem.id).map(qItem => (
                            <div key={qItem.id}>
                              <b>{qItem.title}</b><br />
                              <i>{qItem.description}</i><br />
                              <input type="text" size="20" onKeyUp={event => updateSurveyResults(event.currentTarget.value, `${qItem.id}`, `${qItem.surveyID}`)} />
                              <br />
                            </div>
                          )) : ""}
                          <button onClick={event => saveSurveyResults(`${sItem.id}`)}>Save survey results</button>
                        </Card.Body>
                      </Card>
                      <br />
                    </div>

                  ))}
                </>
                : <>No Surveys</>
              }
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
